import React from "react";
import {
  CssBaseline,
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  createStyles,
  useMediaQuery
} from "@material-ui/core";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import AppBody from "./components/AppBody";
import { BrowserRouter as Router } from "react-router-dom";

// create the app style sheet
const useStyles = makeStyles(theme =>
  createStyles({
    AppHeader: {},
    AppBody: {
      flexGrow: 1
    },
    AppFooter: { marginBottom: theme.spacing(1) }
  })
);

// function to create the global theme for the app
function createTheme(prefersDarkMode: boolean) {
  return createMuiTheme({
    palette: {
      type: prefersDarkMode ? "dark" : "light"
    }
  });
}

const App: React.FC = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(() => createTheme(prefersDarkMode), [
    prefersDarkMode
  ]);

  const classes = useStyles();
  return (
    <React.Fragment>
      <Router>
        <ThemeProvider theme={theme}>
          {/* css baseline for the entire app */}
          <CssBaseline />
          <AppHeader className={classes.AppHeader} />
          <AppBody className={classes.AppBody} />
          <AppFooter className={classes.AppFooter} />
        </ThemeProvider>
      </Router>
    </React.Fragment>
  );
};

export default App;
