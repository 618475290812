// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { RenderInputParams } from "@material-ui/lab/Autocomplete";
import {
  makeStyles,
  createStyles,
  CircularProgress,
  Grid,
  Typography,
  Avatar
} from "@material-ui/core";
import { GeniusHits } from "../../util/geniusTypes";
import { IBaseComponentProps } from "../../util/interfaces";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { fetchGeniusSearch } from "../../util/geniusApi";

const useStyles = makeStyles(theme =>
  createStyles({
    autocomplete: {},
    optionImage: {
      height: theme.spacing(4.75),
      width: theme.spacing(4.75)
    },
    songViewIcon: {
      marginRight: theme.spacing(1)
    }
  })
);

export interface ISongSearchProps extends IBaseComponentProps {}

export default function SongSearch(props: ISongSearchProps) {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<GeniusHits[]>([]);
  const [selected, setSelected] = React.useState<GeniusHits | undefined>(
    undefined
  );
  const loading = open && options.length === 0;

  // make the input of the autocomplete a controlled component
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{}>,
    value: GeniusHits
  ) => {
    setSelected(value);
  };

  // fetch when the input value changes
  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    (async () => {
      const results = await fetchGeniusSearch(inputValue);

      // only set results using must recent fetch
      if (active) {
        setOptions(results);
      }
    })();

    return () => {
      active = false;
    };
  }, [inputValue]);

  const classes = useStyles();

  const history = useHistory();

  if (selected !== undefined) {
    history.push(`/songs/${selected.result.id}`);
  }

  return (
    <Autocomplete
      debug
      className={`${classes.autocomplete} ${props.className}`}
      options={options}
      // all options are eligible
      filterOptions={o => o}
      open={open}
      loading={loading}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => (option as GeniusHits).result.full_title}
      renderInput={params => SongSearchInput(params, handleChange, loading)}
      renderOption={SongSearchOption(classes)}
      onChange={handleSelectChange}
      disableOpenOnFocus
      autoHighlight
      autoComplete
      autoSelect
    />
  );
}

function SongSearchOption(classes: ReturnType<typeof useStyles>) {
  return (option: GeniusHits) => {
    return (
      <Grid container justify="flex-start" alignItems="center">
        {/* left side is an image */}
        <Grid container item xs={1}>
          <Avatar
            variant="square"
            src={option.result.song_art_image_thumbnail_url}
            alt={`${option.result.full_title}`}
            className={classes.optionImage}
          ></Avatar>
        </Grid>
        {/* right side is multi line text */}
        <Grid
          container
          item
          xs={11}
          direction="column"
          alignItems="flex-start"
          justify="center"
        >
          {/* top line */}
          <Grid item>
            <Typography variant="body1" noWrap>
              {option.result.title_with_featured}
            </Typography>
          </Grid>
          {/* bottom line */}
          <Grid container item alignItems="center" justify="space-between">
            {/* artist  */}
            <Grid item xs={6}>
              <Typography variant="body2" noWrap color="textSecondary">
                {option.result.primary_artist.name}
              </Typography>
            </Grid>
            {/* views */}
            <Grid container item xs={6} justify="flex-end">
              <VisibilityIcon className={classes.songViewIcon} fontSize="small">
                song_views
              </VisibilityIcon>
              <Typography variant="body2" noWrap color="textSecondary">
                {(+option.result.stats.pageviews || 0).toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
}

function SongSearchInput(
  params: RenderInputParams,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  loading: boolean
): React.ReactNode {
  return (
    <TextField
      {...params}
      label="Search a song"
      fullWidth
      variant="outlined"
      onChange={handleChange}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        )
      }}
    />
  );
}
