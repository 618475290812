import {
  GeniusHits,
  GeniusSong,
  GeniusShallowSong,
  GeniusArtist
} from "./geniusTypes";

const AUTH_TOKEN = process.env.REACT_APP_GENIUS_ACCESS_TOKEN;

if (AUTH_TOKEN === undefined) {
  throw Error("the AUTH_TOKEN is undefined");
}

/**
 * Search Genius API
 * @param query the search query
 */
export const fetchGeniusSearch: (
  query: string
) => Promise<GeniusHits[]> = async (query: string) => {
  let requestURL = new URL("https://api.genius.com/search");
  requestURL.searchParams.append("q", query);
  requestURL.searchParams.append("access_token", AUTH_TOKEN);

  // TODO(lukemurray): error handling
  return await fetch(requestURL.href)
    .then(response => response.json())
    .then(json => {
      return json.response.hits;
    });
};

export const fetchGeniusSong: (songId: number) => Promise<GeniusSong> = async (
  songId: number
) => {
  let requestURL = new URL(`https://api.genius.com/songs/${songId}`);
  requestURL.searchParams.append("text_format", "plain");
  requestURL.searchParams.append("access_token", AUTH_TOKEN);

  // TODO(lukemurray): error handling
  return await fetch(requestURL.href)
    .then(response => response.json())
    .then(json => {
      return json.response.song;
    });
};

export const fetchGeniusArtist: (
  artistId: number
) => Promise<GeniusArtist> = async (artistId: number) => {
  let requestURL = new URL(`https://api.genius.com/artists/${artistId}`);
  requestURL.searchParams.append("text_format", "plain");
  requestURL.searchParams.append("access_token", AUTH_TOKEN);

  // TODO(lukemurray): error handling
  return await fetch(requestURL.href)
    .then(response => response.json())
    .then(json => {
      return json.response.artist;
    });
};

export const fetchArtistSongs: (
  artistId: number,
  page: number
) => Promise<{
  songs: GeniusShallowSong[];
  nextPage: number | undefined;
}> = async (artistId: number, page: number) => {
  // artists/1421/songs?page=2&per_page=20&sort=popularity

  const resultsPerPage = 10;
  const sort = "popularity";
  const requestURL = new URL(
    `https://api.genius.com/artists/${artistId}/songs`
  );
  requestURL.searchParams.set("page", page + "");
  requestURL.searchParams.set("per_page", resultsPerPage + "");
  requestURL.searchParams.set("sort", sort);
  requestURL.searchParams.set("access_token", AUTH_TOKEN);

  return await fetch(requestURL.href)
    .then(response => response.json())
    .then(json => {
      return {
        songs: json.response.songs,
        nextPage:
          json.response.next_page === null
            ? undefined
            : +json.response.next_page
      };
    });
};
