import React from "react";
import { IBaseComponentProps } from "../util/interfaces";
import { Link, Typography, Container, Grid } from "@material-ui/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://lsmurray.com/">
        Luke Murray
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Credits() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Credit to ♥ "}
      <Link href="http://genius.com/" color="inherit">
        Genius
      </Link>
    </Typography>
  );
}

interface IAppFooterProps extends IBaseComponentProps {}

const AppFooter: React.FC<IAppFooterProps> = props => {
  return (
    <footer className={props.className}>
      <Container maxWidth="sm">
        <Grid container direction="column">
          <Grid item xs={12}>
            <Credits />
          </Grid>
          <Grid item xs={12}>
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default AppFooter;
