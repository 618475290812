import { GeniusMediaProvider } from "./geniusTypes";
import {
  faYoutube,
  faSpotify,
  faSoundcloud
} from "@fortawesome/free-brands-svg-icons";

export const geniusProviderColor = (provider: GeniusMediaProvider) => {
  switch (provider) {
    case GeniusMediaProvider.SOUNDCLOUD:
      return "#ff7700";
    case GeniusMediaProvider.SPOTIFY:
      return "#1DB954";
    case GeniusMediaProvider.YOUTUBE:
      return "#C4302B";
  }
};

export const geniusProviderIcon = (provider: GeniusMediaProvider) => {
  switch (provider) {
    case GeniusMediaProvider.SOUNDCLOUD:
      return faSoundcloud;
    case GeniusMediaProvider.SPOTIFY:
      return faSpotify;
    case GeniusMediaProvider.YOUTUBE:
      return faYoutube;
  }
};
