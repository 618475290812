import {
  useRouteMatch,
  Route,
  Redirect,
  useParams,
  Switch,
  useHistory
} from "react-router-dom";

import React, { useState, useEffect } from "react";
import {
  GeniusSong,
  PerformanceMetaGroup,
  PerformanceMeta
} from "../../util/geniusTypes";
import { fetchGeniusSong } from "../../util/geniusApi";
import {
  Container,
  Typography,
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Avatar,
  CardHeader,
  Card,
  CardActionArea
} from "@material-ui/core";
import { IBaseComponentProps } from "../../util/interfaces";
import { tryCreateSpotifyPlayEmbed } from "../../util/spotify";
import { getPerformanceGroupsFromSong } from "../../util/performanceGroups";
import KeyBindingUtil from "../../util/keybindingUtil";

// the songs page
export const Songs = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:songId`}>
        <Song />
      </Route>
      <Route path={match.path}>
        <Redirect to="/"></Redirect>
      </Route>
    </Switch>
  );
};

// the page for a single song
export const Song = () => {
  // get the song id
  let { songId } = useParams();
  if (songId === undefined) {
    throw Error("the songId is undefined");
  }

  // state
  const [song, setSong] = useState<GeniusSong | undefined>(undefined);
  const loading = song === undefined;

  // request the song
  useEffect(() => {
    (async () => {
      const result = await fetchGeniusSong(+songId!);
      setSong(result);
    })();
  }, [songId]);

  const performanceGroups = React.useMemo(
    () => getPerformanceGroupsFromSong(song),
    [song]
  );

  const useStyles = React.useMemo(
    () =>
      makeStyles((theme: Theme) =>
        createStyles({
          songGrid: {
            marginTop: theme.spacing(2)
          }
        })
      ),
    []
  );

  const spotifyPlayHTML = React.useMemo(() => tryCreateSpotifyPlayEmbed(song), [
    song
  ]);
  const classes = useStyles();

  // return if the song is loading
  if (loading) {
    return <Typography variant="body1">loading</Typography>;
  }
  if (song === undefined) {
    throw Error("song is undefined");
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.songGrid}
      >
        <Grid item xs={12} sm={10} md={8} lg={6} key="song-title">
          <Typography variant="h4">{song.title_with_featured}</Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6} key="song-description">
          <Typography variant="body1">{song.description.plain}</Typography>
        </Grid>
        {spotifyPlayHTML !== undefined && (
          <Grid item xs={12} sm={10} md={8} lg={6} key="song-spotify">
            <div dangerouslySetInnerHTML={spotifyPlayHTML}></div>
          </Grid>
        )}
        <Grid item xs={12} sm={10} md={8} lg={6} key="song-credits">
          <Typography variant="h5">Credits</Typography>
        </Grid>
      </Grid>
      {performanceGroups.map(performanceGroup => (
        <PerformanceGroup
          key={performanceGroup.label}
          performanceGroup={performanceGroup}
        ></PerformanceGroup>
      ))}
    </Container>
  );
};

// component for a performance group (i.e. producers, writers, featured etc...)
export interface IPerformanceGroupProps {
  performanceGroup: PerformanceMetaGroup;
}

export const PerformanceGroup: React.FC<IPerformanceGroupProps> = props => {
  const { performanceGroup } = props;

  const useStyles = React.useMemo(
    () =>
      makeStyles((theme: Theme) =>
        createStyles({
          paper: {
            padding: theme.spacing(2)
          },
          performanceGrid: {
            marginTop: theme.spacing(2)
          }
        })
      ),
    []
  );

  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      className={classes.performanceGrid}
    >
      <Grid item xs={12}>
        <Typography variant="h6">{performanceGroup.label}</Typography>
      </Grid>
      {performanceGroup.performances.map(performance => (
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          key={performance.artist.id + "-" + performance.label}
        >
          <Performance
            performance={performance}
            className={classes.paper}
          ></Performance>
        </Grid>
      ))}
    </Grid>
  );
};

// component for an individual performance
interface IPerformanceProps extends IBaseComponentProps {
  performance: PerformanceMeta;
}

export const Performance: React.FC<IPerformanceProps> = props => {
  const { performance } = props;
  const [clicked, setClicked] = React.useState(false);

  const useStyles = React.useMemo(
    () =>
      makeStyles((theme: Theme) =>
        createStyles({
          artistImage: {
            width: theme.spacing(8),
            height: theme.spacing(8)
          }
        })
      ),
    []
  );

  const classes = useStyles();

  const history = useHistory();

  const artistURL = `/artists/${performance.artist.id}`;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (KeyBindingUtil.hasCommandModifier(e)) {
      window.open(artistURL, "_blank");
    } else {
      setClicked(true);
    }
  };

  if (clicked) {
    history.push(artistURL);
  }

  return (
    <Card>
      <CardActionArea onClick={handleClick}>
        <CardHeader
          avatar={
            <Avatar
              src={performance.artist.image_url}
              alt={`${performance.artist.name}`}
              className={classes.artistImage}
            />
          }
          title={performance.artist.name}
          titleTypographyProps={{ variant: "h6" }}
          subheader={performance.label}
          subheaderTypographyProps={{
            variant: "body2",
            color: "textSecondary"
          }}
        ></CardHeader>
      </CardActionArea>
    </Card>
  );
};
