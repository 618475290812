export enum GeniusIndexEnum {
  song = "song"
}

export enum GeniusTypeEnum {
  song = "song"
}

export type GeniusHits = {
  highlights: any[];
  index: GeniusIndexEnum;
  type: GeniusTypeEnum;
  result: GeniusShallowSong;
};

export type GeniusShallowSong = {
  annotation_count: number;
  api_path: string;
  full_title: string;
  header_image_thumbnail_url: string;
  header_image_url: string;
  id: number;
  path: string;
  pyongs_count: number;
  song_art_image_thumbnail_url: string;
  song_art_image_url: string;
  stats: GeniusSearchStats;
  title: string;
  title_with_featured: string;
  url: string;
  primary_artist: GeniusShallowArtist;
};

export type GeniusSearchStats = {
  unreviewed_annotations: number;
  hot: boolean;
  pageviews: number;
};

export type GeniusShallowArtist = {
  api_path: string;
  header_image_url: string;
  id: number;
  image_url: string;
  is_meme_verified: boolean;
  is_verified: boolean;
  name: string;
  url: string;
  iq: number;
};

export type GeniusSongStats = {
  accepted_annotations: number;
  contributors: number;
  iq_earners: number;
  transcribers: number;
  unreviewed_annotations: number;
  verified_annotations: number;
  concurrents: number;
  hot: boolean;
  pageviews: number;
};

type GeniusAlbum = {
  api_path: string;
  cover_art_url: string;
  full_title: string;
  id: number;
  name: string;
  url: string;
  artist: GeniusShallowArtist;
};

type GeniusCustomPerformance = {
  label: string;
  artists: GeniusShallowArtist[];
};

type GeniusSongRelationship = {
  type: string;
  songs: GeniusShallowSong[];
};

export enum GeniusMediaProvider {
  SPOTIFY = "spotify",
  YOUTUBE = "youtube",
  SOUNDCLOUD = "soundcloud"
}

type GeniusMedia = {
  // all media types
  provider: GeniusMediaProvider;
  type: string;
  url: string;

  // youtube
  start?: number;

  // spotify
  native_uri?: string;

  // soundcloud
  attribution?: string;
};

export type GeniusDescription = {
  plain: string;
};

export type GeniusSong = {
  annotation_count: number;
  api_path: string;
  apple_music_id: string;
  apple_music_player_url: string;
  featured_video: boolean;
  full_title: string;
  header_image_thumbnail_url: string;
  header_image_url: string;
  id: number;
  lyrics_owner_id: number;
  lyrics_state: string;
  path: string;
  pyongs_count: number;
  recording_location: null;
  // yyyy-mm-dd
  release_date: string;
  song_art_image_thumbnail_url: string;
  song_art_image_url: string;
  stats: GeniusSongStats;
  title: string;
  title_with_featured: string;
  url: string;
  album: GeniusAlbum;
  custom_performances: GeniusCustomPerformance[];
  featured_artists: GeniusShallowArtist[];
  media: GeniusMedia[];
  primary_artist: GeniusShallowArtist;
  producer_artists: GeniusShallowArtist[];
  song_relationships: GeniusSongRelationship[];
  writer_artists: GeniusShallowArtist[];
  description: GeniusDescription;
};

export type GeniusArtist = {
  alternate_names: string[];
  api_path: string;
  description: GeniusDescription;
  facebook_name: string;
  followers_count: number;
  header_image_url: string;
  id: number;
  image_url: string;
  instagram_name: string;
  is_meme_verified: false;
  is_verified: true;
  name: string;
  translation_artist: false;
  twitter_name: string;
  url: string;
  iq: number;
};

// performance on a song
export type PerformanceMeta = {
  label: string;
  artist: GeniusShallowArtist;
};

// group of performances with same label on a song
export type PerformanceMetaGroup = {
  label: string;
  performances: PerformanceMeta[];
};
