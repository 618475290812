import React from "react";
const isOSX = navigator.platform.toLowerCase().indexOf("mac") >= 0;

const KeyBindingUtil = {
  /**
   * Check whether the ctrlKey modifier is *not* being used in conjunction with
   * the altKey modifier. If they are combined, the result is an `altGraph`
   * key modifier, which should not be handled by this set of key bindings.
   */
  isCtrlKeyCommand: function(
    e: React.KeyboardEvent<{}> | React.MouseEvent<{}>
  ): boolean {
    return !!e.ctrlKey && !e.altKey;
  },

  isOptionKeyCommand: function(
    e: React.KeyboardEvent<{}> | React.MouseEvent<{}>
  ): boolean {
    return isOSX && e.altKey;
  },

  usesMacOSHeuristics: function(): boolean {
    return isOSX;
  },

  hasCommandModifier: function(
    e: React.KeyboardEvent<{}> | React.MouseEvent<{}>
  ): boolean {
    return isOSX
      ? !!e.metaKey && !e.altKey
      : KeyBindingUtil.isCtrlKeyCommand(e);
  }
};

export default KeyBindingUtil;
