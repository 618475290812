import { GeniusSong } from "./geniusTypes";

export function tryCreateSpotifyPlayEmbed(song?: GeniusSong) {
  if (song === undefined) {
    return undefined;
  }
  const spotifyInfo = song.media.find(
    mediaSource => mediaSource.provider === "spotify"
  );

  if (spotifyInfo === undefined) {
    return undefined;
  }
  const spotifyInfoArray = spotifyInfo.native_uri!.split(":");
  const spotifyResourceID = spotifyInfoArray.pop();
  const spotifyResourceType = spotifyInfoArray.pop();

  return {
    __html: `<iframe src="https://open.spotify.com/embed/${spotifyResourceType}/${spotifyResourceID}" width="250" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`
  };
}
