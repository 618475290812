import React from "react";
import { IBaseComponentProps } from "../util/interfaces";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Theme,
  createStyles,
  Typography
} from "@material-ui/core";
import { Fingerprint as LogoIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";

interface IAppHeaderProps extends IBaseComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      marginRight: theme.spacing(2),
      color: "inherit",
      textDecoration: "none"
    },
    title: {
      flexGrow: 1,
      color: "inherit",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  })
);

const AppHeader: React.FC<IAppHeaderProps> = () => {
  const classes = useStyles();

  return (
    <header>
      <AppBar position="static">
        <Toolbar>
          <Link to="/" className={classes.logo}>
            <LogoIcon />
          </Link>
          <Typography
            variant="h6"
            className={classes.title}
            component={Link}
            to="/"
          >
            Genius Producers
          </Typography>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default AppHeader;
