import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory
} from "react-router-dom";
import React from "react";
import {
  Container,
  Chip,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Grid,
  Card,
  CardHeader,
  Avatar,
  CardActionArea,
  CircularProgress,
  CardContent,
  IconButton
} from "@material-ui/core";
import {
  GeniusArtist,
  GeniusShallowSong,
  GeniusSong,
  GeniusMediaProvider
} from "../../util/geniusTypes";
import {
  fetchGeniusArtist,
  fetchArtistSongs,
  fetchGeniusSong
} from "../../util/geniusApi";
import { Visibility as VisibilityIcon } from "@material-ui/icons";
import { getPerformanceGroupsFromSong } from "../../util/performanceGroups";
import KeyBindingUtil from "../../util/keybindingUtil";
import InfiniteScroll from "react-infinite-scroller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { geniusProviderIcon, geniusProviderColor } from "../../util/brandUtil";

// the artists page
export const Artists = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:artistId`}>
        <Artist />
      </Route>
      <Route path={match.path}>
        <Redirect to="/"></Redirect>
      </Route>
    </Switch>
  );
};

export const Artist = () => {
  // get the artist id
  let { artistId } = useParams();
  if (artistId === undefined) {
    throw Error("the artistId is undefined");
  }

  // the artist this component is rendering
  const [artist, setArtist] = React.useState<GeniusArtist | undefined>(
    undefined
  );
  // the next page of songs to fetch from the api
  const startPage = 1;
  const [nextSongPage, setNextSongPage] = React.useState<number | undefined>(
    startPage
  );
  // the songs which are currently loaded
  const [songs, setSongs] = React.useState<GeniusShallowSong[]>([]);
  const [loadMore, setLoadMore] = React.useState(false);
  const componentIsLoading = artist === undefined;

  const useStyles = React.useMemo(
    () =>
      makeStyles((theme: Theme) =>
        createStyles({
          songGrid: {
            marginTop: theme.spacing(2)
          }
        })
      ),
    []
  );

  const classes = useStyles();

  // request the artist
  React.useEffect(() => {
    (async () => {
      const result = await fetchGeniusArtist(+artistId!);
      setArtist(result);
    })();
  }, [artistId]);

  // request the artist
  React.useEffect(() => {
    let active = true;
    (async () => {
      if (nextSongPage === undefined) {
        return;
      }
      if (!loadMore) {
        return;
      }
      await fetchArtistSongs(+artistId!, nextSongPage!).then(result => {
        if (active) {
          setSongs([...songs, ...result.songs]);
          setNextSongPage(result.nextPage);
          setLoadMore(false);
        }
      });
    })();
    return () => {
      active = false;
    };
  }, [artistId, loadMore, nextSongPage, songs]);

  // return if the artist is loading
  if (componentIsLoading) {
    return <Typography variant="body1">loading</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.songGrid}
      >
        <Grid item xs={12} sm={10} md={8} lg={6} key="artist-title">
          <Typography variant="h4">{artist!.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6} key="artist-description">
          <Typography variant="body1">{artist!.description.plain}</Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={8} lg={6} key="artist-song-header">
          <Typography variant="h5">Songs</Typography>
        </Grid>
      </Grid>
      <InfiniteScroll
        //@ts-ignore
        element={Grid}
        //@ts-ignore
        container
        spacing={2}
        pageStart={startPage}
        loadMore={() => setLoadMore(true)}
        hasMore={nextSongPage !== undefined}
        key="artist-song-grid"
        loader={
          <React.Fragment key="loading-wrapper">
            <Grid item xs={12} key="loading-text">
              <Typography variant="h6" color="textSecondary" align="center">
                Loading
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justify="center"
              key="loading-progress"
            >
              <CircularProgress />
            </Grid>
          </React.Fragment>
        }
      >
        {songs.map(song => (
          <ArtistSong
            song={song}
            key={song.id}
            artistId={artist!.id}
          ></ArtistSong>
        ))}
      </InfiniteScroll>
    </Container>
  );
};

interface IArtistSongProps {
  song: GeniusShallowSong;
  artistId: number;
}

const ArtistSong: React.FC<IArtistSongProps> = props => {
  const { song: shallowSong } = props;
  const [song, setSong] = React.useState<GeniusSong | undefined>(undefined);

  const [clicked, setClicked] = React.useState(false);

  const useStyles = React.useMemo(
    () =>
      makeStyles((theme: Theme) =>
        createStyles({
          songImage: {
            width: theme.spacing(4),
            height: theme.spacing(4)
          },
          songViewIcon: {
            marginRight: theme.spacing(0.5)
          },
          chipGrid: {
            marginTop: theme.spacing(0.25),
            marginBottom: theme.spacing(1)
          }
        })
      ),
    []
  );

  const classes = useStyles();
  const history = useHistory();

  // request the song
  React.useEffect(() => {
    (async () => {
      const result = await fetchGeniusSong(shallowSong.id);
      setSong(result);
    })();
  }, [shallowSong.id]);

  const roles = React.useMemo(() => {
    const performanceGroups = getPerformanceGroupsFromSong(song);
    return performanceGroups
      .filter(performanceGroup =>
        performanceGroup.performances.some(
          performance => performance.artist.id === props.artistId
        )
      )
      .map(performanceGroup => performanceGroup.label);
  }, [props.artistId, song]);

  const songURL = `/songs/${shallowSong.id}`;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (KeyBindingUtil.hasCommandModifier(e)) {
      window.open(songURL, "_blank");
    } else {
      setClicked(true);
    }
  };

  if (clicked) {
    history.push(songURL);
  }

  return (
    <Grid item xs={6} md={4} lg={3}>
      <Card>
        <CardActionArea onClick={handleClick}>
          <CardHeader
            avatar={
              <Avatar
                src={shallowSong.song_art_image_url}
                alt={`${shallowSong.full_title}`}
                className={classes.songImage}
              />
            }
            disableTypography={true}
            title={
              <Typography variant="body1">
                {shallowSong.title_with_featured}
              </Typography>
            }
            subheader={
              <Grid container justify="space-between">
                <Grid
                  container
                  item
                  xs={6}
                  justify="flex-start"
                  key="artist-name"
                >
                  <Typography variant="body2" color="textSecondary">
                    {shallowSong.primary_artist.name}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  justify="flex-end"
                  key="song-view-wrapper"
                >
                  {song !== undefined && (
                    <>
                      <Typography
                        variant="body2"
                        noWrap
                        color="textSecondary"
                        key="song-view-icon"
                      >
                        <VisibilityIcon
                          className={classes.songViewIcon}
                          fontSize="small"
                          color="inherit"
                        >
                          song_views
                        </VisibilityIcon>
                      </Typography>
                      <Typography
                        variant="body2"
                        noWrap
                        color="textSecondary"
                        key="song-view-count"
                      >
                        {(+song.stats.pageviews || 0).toLocaleString()}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            }
          ></CardHeader>
        </CardActionArea>
        {song !== undefined && song.media.length !== 0 && (
          <CardContent>
            <Grid container spacing={1}>
              {song.media
                .filter(m =>
                  Object.values(GeniusMediaProvider).includes(m.provider)
                )
                .sort((a, b) => (a.provider > b.provider ? 1 : -1))
                .map(mediaSource => (
                  <Grid item key={mediaSource.provider}>
                    <IconButton
                      href={mediaSource.native_uri || mediaSource.url}
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        color={geniusProviderColor(mediaSource.provider)}
                        icon={geniusProviderIcon(mediaSource.provider)}
                      />
                    </IconButton>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        )}
      </Card>
      {song !== undefined && (
        <Grid
          container
          direction="column"
          spacing={1}
          className={classes.chipGrid}
        >
          <Grid container item xs={12} spacing={1}>
            {roles.map(role => (
              <Grid item key={role}>
                <Chip variant="outlined" size="small" label={role} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
