import React from "react";
import { IBaseComponentProps } from "../util/interfaces";
import { makeStyles, createStyles, Container } from "@material-ui/core";
import SongSearch from "./SongSearch";
import { Switch, Route } from "react-router-dom";
import { Songs } from "./Songs";
import { Artists } from "./Artists";

interface IAppBodyProps extends IBaseComponentProps {}

// create the app style sheet
const useStyles = makeStyles(() =>
  createStyles({
    BodyGrid: {
      height: "100%"
    },
    SongSearch: {
      // position song search 25% of the way down the page
      position: "relative",
      top: "25%"
    }
  })
);

const AppBody: React.FC<IAppBodyProps> = props => {
  const classes = useStyles();
  return (
    <main className={props.className}>
      <Switch>
        {/* songs page */}
        <Route path="/songs">
          <Songs></Songs>
        </Route>
        {/* artists page */}
        <Route path="/artists">
          <Artists></Artists>
        </Route>
        {/* home page and search page */}
        <Route path="/">
          <Container maxWidth="sm" className={classes.BodyGrid}>
            <SongSearch className={classes.SongSearch} />
          </Container>
        </Route>
      </Switch>
    </main>
  );
};

export default AppBody;
