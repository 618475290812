import {
  GeniusSong,
  PerformanceMetaGroup,
  PerformanceMeta
} from "./geniusTypes";

import groupBy from "lodash.groupby";

export function getPerformanceGroupsFromSong(song?: GeniusSong) {
  const performances = getPerformancesFromSong(song);
  const performanceGroupDict = groupBy(performances, v => v.label);
  const performanceGroups = Object.keys(performanceGroupDict).map(
    group =>
      ({
        label: group,
        performances: performanceGroupDict[group]
      } as PerformanceMetaGroup)
  );
  return performanceGroups.sort(performanceGroupSort());
}

function performanceGroupSort():
  | ((a: PerformanceMetaGroup, b: PerformanceMetaGroup) => number)
  | undefined {
  // the sort order
  const sortOrder: Record<string, number> = {
    "Primary Artist": 1,
    Primary: 2,
    Featured: 3,
    Producer: 4,
    Writer: 5
  };
  return (a, b) => {
    let aa = a.label;
    let bb = b.label;
    // if both in the sort order rank them
    if (aa in sortOrder && bb in sortOrder) {
      return (
        ((sortOrder[aa] > sortOrder[bb]) as any) -
        ((sortOrder[aa] < sortOrder[bb]) as any)
      );
    }
    // if aa in sort order then it comes before bb which is not in sort order
    if (aa in sortOrder && !(bb in sortOrder)) {
      return -1;
    }
    // if aa not in sort order then it comes after bb which is in sort order
    if (bb in sortOrder && !(aa in sortOrder)) {
      return 1;
    }
    // otherwise return the proper ordering
    return (((aa as any) > bb) as any) - (((aa as any) < bb) as any);
  };
}

function getPerformancesFromSong(song?: GeniusSong) {
  let performances: PerformanceMeta[] = [];
  if (song === undefined) {
    return [];
  }

  // add the custom performances
  song.custom_performances.forEach(customPerformance =>
    customPerformance.artists.forEach(artist =>
      performances.push({
        artist,
        label: customPerformance.label
      })
    )
  );
  song.featured_artists.forEach(artist => {
    performances.push({
      label: "Featured",
      artist
    });
  });
  song.writer_artists.forEach(artist => {
    performances.push({
      label: "Writer",
      artist
    });
  });
  song.producer_artists.forEach(artist => {
    performances.push({
      label: "Producer",
      artist
    });
  });
  performances.push({
    label: "Primary",
    artist: song.primary_artist
  });
  return performances;
}
